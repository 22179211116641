/* BubbleButton.css */

/* Basic styling for the bubble button */
.bubble-button {
    width: 80%;
    background-color: white;
    color: #333;
    border-radius: 25px;
    padding: 2px 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    position: relative;
    font-family: 'Noto Sans', sans-serif;
    margin: 15px;
}

    /* Remove the default focus outline */
    .bubble-button:focus {
        outline: none;
    }

    /* Hover effect to make it "bubble" */
    .bubble-button:hover {
        transform: scale(1.05);
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    }

    /* Ensure no overflow */
    .bubble-button span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

@media (max-width: 1024px) {
    .bubble-button {
        width: 80%;
    }
}

@media (max-width: 768px) {
    .bubble-button {
        width: 80%;
    }
}
